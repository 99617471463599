import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  FormLabel,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

const drawerWidth = 270;

export default function UpgradeSubscription() {
  const [billingPeriod, setBillingPeriod] = React.useState("Annum");

  const handleChange = (event) => {
    setBillingPeriod(event.target.value);
  };

  const subscriptions = [
    { price: 100, version: "Lite" },
    { price: 150, version: "Standard" },
    { price: 200, version: "Pro" },
  ];

  const [subValue, setSubValue] = React.useState("Lite");

  const handleSubChange = (event) => {
    setSubValue(event.target.value);
  };

  const handleButtonClick = (value) => {
    setSubValue(value);
  };

  return (
    <>
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          // p: 3,
        }}
      >
        <Typography
          sx={{
            px: 3,
            py: 2,
            "@media (max-width: 600px)": {
              pl: 8, // margin value for small screens only
            },
            color: "#737173",
            fontSize: "25px",
          }}
        >
          Upgrade Subscription
        </Typography>
        <Divider />
        <Box sx={{ m: 3 }}>
          <FormControl>
            <RadioGroup
              overlay
              name="subscription"
              defaultValue="Lite"
              value={subValue}
              onChange={handleSubChange}
            >
              <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
                {subscriptions.map((sub, idx) => (
                  <Card
                    key={idx}
                    variant="outlined"
                    sx={{
                      width: 270,
                      border: "1px solid #1C62F1",
                      bgcolor: subValue === sub.version ? "#1C62F1" : "#fff",
                      color: subValue === sub.version ? "#fff" : "#000",
                    }}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography>{sub.version}</Typography>
                        <Radio
                          value={sub.version}
                          sx={{
                            color: "#000",
                            "&.Mui-checked": { color: "#fff" },
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: "500", fontSize: "54px" }}
                        >
                          ${sub.price}
                        </Typography>
                        <Typography>Per Annum</Typography>
                      </Box>
                    </CardContent>
                    <CardActions>
                      <Box sx={{ display: "flex", width: "100%" }}>
                        {subValue === sub.version ? (
                          <Button
                            variant="conatined"
                            sx={{
                              flex: 1,
                              mx: 3,
                              mb: 3,
                              bgcolor: "#fff",
                              color: "#1C62F1",
                              "&:hover": { backgroundColor: "#fff" },
                            }}
                          >
                            Selected
                          </Button>
                        ) : (
                          <Button
                            variant="outlined"
                            sx={{
                              flex: 1,
                              mx: 3,
                              mb: 3,
                              bgcolor: "#fff",
                              color: "#1C62F1",
                            }}
                            onClick={() => handleButtonClick(sub.version)}
                          >
                            Choose
                          </Button>
                        )}
                      </Box>
                    </CardActions>
                  </Card>
                ))}
              </Box>
            </RadioGroup>
          </FormControl>

          <Box sx={{ my: 3 }}>
            <Typography sx={{ color: "#1C62F1" }}>
              Subscription Terms
            </Typography>

            <Box
              sx={{
                display: "flex",
                // flexDirection: "column",
                // gap: "45px",
                alignItems: "center",
                mt: 3,
              }}
            >
              <FormLabel
                sx={{ color: "#000", width: "200px" }}
                id="firstName-label"
              >
                Starts on
              </FormLabel>
              <TextField
                type="date"
                variant="outlined"
                size="small"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                // flexDirection: "column",
                // gap: "45px",
                alignItems: "center",
                mt: 3,
              }}
            >
              <FormLabel
                sx={{ color: "#000", width: "200px" }}
                id="firstName-label"
              >
                Billing Period
              </FormLabel>
              <Select
                id="title-label"
                value={billingPeriod}
                onChange={handleChange}
                size="small"
                sx={{ width: "290px", bgcolor: "#fff" }}
              >
                <MenuItem value={"Annum"}>Annum</MenuItem>
                <MenuItem value={"Monthly"}>Monthly</MenuItem>
              </Select>
            </Box>

            <Box
              sx={{
                display: "flex",
                // flexDirection: "column",
                // gap: "45px",
                alignItems: "center",
                mt: 3,
              }}
            >
              <FormLabel
                sx={{ color: "#000", width: "200px" }}
                id="firstName-label"
              >
                Expires After
              </FormLabel>
              <TextField
                type="number"
                variant="outlined"
                size="small"
                sx={{ width: "290px", bgcolor: "#fff" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">Cycles</InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "20px",
            }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: "#1C62F1",
                "&:hover": {
                  backgroundColor: "#1C62F1",
                },
                width: "100px",
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: "#1C62F1",
                "&:hover": {
                  backgroundColor: "#1C62F1",
                },
                width: "200px",
              }}
            >
              Send Payment Link
            </Button>
            <Button
              variant="contained"
              sx={{
                bgcolor: "#8B8B8B",
                "&:hover": {
                  backgroundColor: "#8B8B8B",
                },
                width: "100px",
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}
