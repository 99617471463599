import { Box, Tab } from "@mui/material";
import React, { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Overview from "./Overview";
import Subscription from "./Subscription";
import { useFormik } from "formik";
import { vendorEditSchema } from "../schemas/Index";
import { editVendor } from "../services/master";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";

export default function EditVendor({ setState, vendorDetails }) {
  const { master } = useSelector((state) => state);
  const [value, setValue] = useState("1");
  const initialValues = {
    id: vendorDetails._id,
    title: vendorDetails.title,
    first_name: vendorDetails.first_name,
    last_name: vendorDetails.last_name,
    phone: vendorDetails.phone,
    mobile: vendorDetails.mobile,
    email: vendorDetails.email,
    password: "",
    POSpassword: "",
    businessName: vendorDetails.businessName,
    businessAddress: vendorDetails.businessAddress,
    addressLine1: vendorDetails.addressLine1,
    addressLine2: vendorDetails.addressLine2,
    gstPercentage: vendorDetails.gstPercentage,
    gstOrVat: vendorDetails.gstOrVat,
    timeZome: vendorDetails.timeZome,
    city: vendorDetails.city,
    country: vendorDetails.country,
    currencySymbol: vendorDetails.currencySymbol,
    postalCode: vendorDetails.postalCode,
    businessStartTime: vendorDetails.businessStartTime,
    businessEndTime: vendorDetails.businessEndTime,
    logo: vendorDetails.logo,
    minOrderValue: vendorDetails.minOrderValue,
    deliveryChargePerItem: vendorDetails.deliveryChargePerItem,
    deliveryRadius: vendorDetails.deliveryRadius,
    freeDeliveryValue: vendorDetails.freeDeliveryValue,
    instagram: vendorDetails.instagram,
    youTube: vendorDetails.youTube,
    googleAccount: vendorDetails.googleAccount,
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: vendorEditSchema,
    onSubmit: async (values, action) => {
      //   const formData = new FormData();
      //   formData.append("data", JSON.stringify(values));
      //   formData.append("image", values.logo);
      const data = await editVendor(master.token, values);
      if (data.status) {
        setState("");
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    },
  });
  return (
    <>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(e, v) => setValue(v)}
            aria-label="lab API tabs example"
          >
            <Tab label="Overview" value="1" />
            {/* <Tab label="Subscription" value="2" /> */}
          </TabList>
        </Box>
        <TabPanel value="1">
          <Overview
            setState={setState}
            setFieldValue={formik.setFieldValue}
            formik={formik}
          />
        </TabPanel>
        {/* <TabPanel value="2">
          <Subscription setState={setState} values={formik.values} />
        </TabPanel> */}
      </TabContext>
    </>
  );
}
