import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Link } from "react-router-dom";

const drawerWidth = 270;

function createData(createdOn, name, lastBilled, expiryDate, version, action) {
  return { createdOn, name, lastBilled, expiryDate, version, action };
}

const rows = [
  createData(
    "08-03-2023",
    "Nesto Hypermarket",
    "08-03-2023",
    "08-03-2024",
    "Pro",
    "some action here"
  ),
];

export default function Subscription() {
  return (
    <>
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          // p: 3,
        }}
      >
        <Typography
          sx={{
            px: 3,
            py: 2,
            "@media (max-width: 600px)": {
              pl: 8, // margin value for small screens only
            },
            color: "#737173",
            fontSize: "25px",
          }}
        >
          Subscription
        </Typography>
        <Divider />
        <Box
          sx={{
            py: 3,
            px: 3,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            id="outlined-basic"
            placeholder="Search"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ bgcolor: "#fff" }}
          />
        </Box>
        <Box sx={{ mx: 3 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Created on</TableCell>
                  <TableCell align="right">Business Name</TableCell>
                  <TableCell align="right">Last Billed</TableCell>
                  <TableCell align="right">Expiry Date</TableCell>
                  <TableCell align="right">Package</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.createdOn}
                    </TableCell>
                    <TableCell align="right">{row.name}</TableCell>
                    <TableCell align="right">{row.lastBilled}</TableCell>
                    <TableCell align="right">{row.expiryDate}</TableCell>
                    <TableCell align="right">{row.version}</TableCell>
                    <TableCell align="right">
                      <Link to="/upgrade-subscription">
                        <InsertDriveFileIcon sx={{ cursor: "pointer" }} />{" "}
                      </Link>
                      <VisibilityIcon />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
}
