import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import SideNavbar from "./componets/SideNavbar";
import LoggedInMaster from "./routes/LoggedInMaster";
import NotLoggedInMaster from "./routes/NotLoggedInMaster";
import MasterDashboard from "./pages/Dashboard";
import AllVendors from "./pages/AllVendors";
import Subscription from "./pages/Subscription";
import UpgradeSubscription from "./pages/UpgradeSubscription";
import Payment from "./pages/Payment";
import MasterLogin from "./pages/MasterLogin";
import ErrorPage from "./pages/ErrorPage";

function App() {
  return (
    <div className="App">
      <SideNavbar />
      <Toaster containerStyle={{ fontFamily: "poppins" }} />
      <Routes>
        <Route element={<LoggedInMaster />}>
          <Route path="/" exact element={<MasterDashboard />} />
          <Route path="/allvendors" exact element={<AllVendors />} />
          <Route path="/subscription" exact element={<Subscription />} />
          <Route
            path="/upgrade-subscription"
            exact
            element={<UpgradeSubscription />}
          />
          <Route path="/payment" exact element={<Payment />} />
        </Route>
        <Route element={<NotLoggedInMaster />}>
          <Route path="/login" element={<MasterLogin />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default App;
