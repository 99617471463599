import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import SearchIcon from "@mui/icons-material/Search";

const drawerWidth = 270;

function createData(vendorId, name, lastPayOut, dueIn, netPayable, action) {
  return { vendorId, name, lastPayOut, dueIn, netPayable, action };
}

const rows = [
  createData(
    "11234353",
    "Nesto Hypermarket",
    "08-03-2023",
    "5 days",
    "₹12,000",
    "some action here"
  ),
];

export default function Payment() {
  const [due, setDue] = React.useState("");

  const handleChange = (event) => {
    setDue(event.target.value);
  };

  return (
    <>
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          // p: 3,
        }}
      >
        <Typography
          sx={{
            px: 3,
            py: 2,
            "@media (max-width: 600px)": {
              pl: 8, // margin value for small screens only
            },
            color: "#737173",
            fontSize: "25px",
          }}
        >
          Payment
        </Typography>
        <Divider />
        <Box
          sx={{
            py: 3,
            px: 3,
            display: "flex",
            gap: "10px",
          }}
        >
          <TextField
            id="outlined-basic"
            placeholder="Search"
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ bgcolor: "#fff" }}
          />
          <Select
            displayEmpty
            renderValue={due !== "" ? undefined : () => "Due in"}
            id="title-label"
            value={due}
            onChange={handleChange}
            size="small"
            placeholder="Due in"
            sx={{ width: "190px", bgcolor: "#fff" }}
          >
            <MenuItem value={"1 Day"}>1 Day</MenuItem>
            <MenuItem value={"1 Week"}>1 Week</MenuItem>
            <MenuItem value={"1 Month"}>1 Month</MenuItem>
          </Select>
        </Box>
        <Box sx={{ mx: 3 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Vendor ID</TableCell>
                  <TableCell align="right">Business Name</TableCell>
                  <TableCell align="right">Last payout</TableCell>
                  <TableCell align="right">Due in</TableCell>
                  <TableCell align="right">Net Payable</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.vendorId}
                    </TableCell>
                    <TableCell align="right">{row.name}</TableCell>
                    <TableCell align="right">{row.lastPayOut}</TableCell>
                    <TableCell align="right">{row.dueIn}</TableCell>
                    <TableCell align="right">{row.netPayable}</TableCell>
                    <TableCell
                      align="right"
                      sx={{ display: "flex", gap: "5px" }}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          border: "1px solid #383B41",
                          color: "#383B41",
                          "&:hover": { border: "1px solid #383B41" },
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{
                          border: "1px solid #F40000",
                          color: "#F40000",
                          "&:hover": { border: "1px solid #F40000" },
                        }}
                      >
                        Payout
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
}
