import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

function NotLoggedInRoutes() {
  const { master } = useSelector((state) => ({ ...state }));
  return master ? <Navigate to="/" /> : <Outlet />;
}

export default NotLoggedInRoutes;
