import {
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const countriesWithSymbols = [
  { country: "INDIA", symbol: "₹", gstOrVat: "GST" },
  { country: "UAE", symbol: "AED", gstOrVat: "VAT" },
  { country: "SAUDI ARABIA", symbol: "SAR", gstOrVat: "VAT" },
  { country: "QATAR", symbol: "QAR", gstOrVat: "VAT" },
  { country: "YAMAN", symbol: "YER", gstOrVat: "-" },
  { country: "OMAN", symbol: "OMR", gstOrVat: "VAT" },
  { country: "KUWAIT", symbol: "KWD", gstOrVat: "-" },
  { country: "BAHRAIN", symbol: "BHD", gstOrVat: "VAT" },
];

export default function Overview({ setState, setFieldValue, formik }) {
  return (
    <>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <>
          <FormLabel
            sx={{ color: "#000", fontFamily: "poppins" }}
            id="logo-label"
          >
            Logo
          </FormLabel>
          <Box
            sx={{
              width: "90px",
              height: "90px",
              border: "1px dashed #000",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <input
              name="logo"
              onChange={(e) => setFieldValue("logo", e.target.files[0])}
              type="file"
              accept="image/*"
              id="logo"
              style={{ display: "none" }}
            />
            <label htmlFor="logo">
              <Box
                sx={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  bgcolor: "red",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
              >
                +
              </Box>
            </label>
          </Box>
          <Typography sx={{ fontFamily: "poppins", fontWeight: "bold" }}>
            Primary Contact
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="title-label"
              >
                Title
              </FormLabel>
              <Select
                name="title"
                onBlur={formik.handleBlur}
                value={formik.values.title}
                error={!!formik.touched.title && !!formik.errors.title}
                helperText={formik.touched.title && formik.errors.title}
                onChange={(e) => setFieldValue("title", e.target.value)}
                id="title-label"
                size="small"
                sx={{ width: "290px", bgcolor: "#fff" }}
              >
                <MenuItem value={"Mr."}>Mr.</MenuItem>
                <MenuItem value={"Mrs."}>Mrs.</MenuItem>
                <MenuItem value={"Ms."}>Ms.</MenuItem>
                <MenuItem value={"Miss"}>Miss</MenuItem>
              </Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="firstName-label"
              >
                First Name
              </FormLabel>
              <TextField
                name="first_name"
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
                error={
                  !!formik.touched.first_name && !!formik.errors.first_name
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
                onChange={(e) => setFieldValue("first_name", e.target.value)}
                type="text"
                variant="outlined"
                size="small"
                placeholder="First Name"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="lastName-label"
              >
                Last Name
              </FormLabel>
              <TextField
                name="last_name"
                onBlur={formik.handleBlur}
                value={formik.values.last_name}
                error={!!formik.touched.last_name && !!formik.errors.last_name}
                helperText={formik.touched.last_name && formik.errors.last_name}
                onChange={(e) => setFieldValue("last_name", e.target.value)}
                type="text"
                variant="outlined"
                size="small"
                placeholder="Last Name"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="phone-label"
              >
                Phone
              </FormLabel>
              <PhoneInput
                country={"in"}
                enableSearch={true}
                autoFormat={true}
                inputStyle={{ width: "290px", height: "40px" }}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                value={formik.values.phone}
                onChange={(e) => setFieldValue("phone", e)}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="mobile-label"
              >
                Mobile
              </FormLabel>
              <PhoneInput
                inputStyle={{ width: "290px", height: "40px" }}
                country={"in"}
                enableSearch={true}
                autoFormat={true}
                inputProps={{
                  name: "mobile",
                  required: true,
                  autoFocus: true,
                }}
                value={formik.values.mobile}
                onChange={(e) => setFieldValue("mobile", e)}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="email-label"
              >
                Email
              </FormLabel>
              <TextField
                name="email"
                onBlur={formik.handleBlur}
                value={formik.values.email}
                error={!!formik.touched.email && !!formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
                onChange={(e) => setFieldValue("email", e.target.value)}
                type="email"
                variant="outlined"
                size="small"
                placeholder="Email address"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="email-label"
              >
                Password
              </FormLabel>
              <TextField
                name="password"
                onBlur={formik.handleBlur}
                value={formik.values.password}
                error={!!formik.touched.password && !!formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
                onChange={(e) => setFieldValue("password", e.target.value)}
                type="password"
                variant="outlined"
                size="small"
                placeholder="Password"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="email-label"
              >
                POS-Password
              </FormLabel>
              <TextField
                name="POSpassword"
                onChange={(e) => setFieldValue("POSpassword", e.target.value)}
                type="password"
                variant="outlined"
                size="small"
                placeholder="POS-Password"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
          </Box>

          <Typography sx={{ fontFamily: "poppins", fontWeight: "bold", mt: 2 }}>
            Business Contact
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
              maxWidth: "1230px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="bizName-label"
              >
                Business Name
              </FormLabel>
              <TextField
                name="businessName"
                onBlur={formik.handleBlur}
                value={formik.values.businessName}
                error={
                  !!formik.touched.businessName && !!formik.errors.businessName
                }
                helperText={
                  formik.touched.businessName && formik.errors.businessName
                }
                onChange={(e) => setFieldValue("businessName", e.target.value)}
                type="text"
                variant="outlined"
                size="small"
                placeholder="Business name"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="bizAddress-label"
              >
                Business Address
              </FormLabel>
              <TextField
                name="businessAddress"
                onBlur={formik.handleBlur}
                value={formik.values.businessAddress}
                error={
                  !!formik.touched.businessAddress &&
                  !!formik.errors.businessAddress
                }
                helperText={
                  formik.touched.businessAddress &&
                  formik.errors.businessAddress
                }
                onChange={(e) =>
                  setFieldValue("businessAddress", e.target.value)
                }
                type="text"
                variant="outlined"
                size="small"
                placeholder="Business Address"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="bizAddress-label"
              >
                Address Line 1
              </FormLabel>
              <TextField
                name="addressLine1"
                onBlur={formik.handleBlur}
                value={formik.values.addressLine1}
                error={
                  !!formik.touched.addressLine1 && !!formik.errors.addressLine1
                }
                helperText={
                  formik.touched.addressLine1 && formik.errors.addressLine1
                }
                onChange={(e) => setFieldValue("addressLine1", e.target.value)}
                type="text"
                variant="outlined"
                size="small"
                placeholder="Address Line 1"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="postal-label"
              >
                Address Line 2
              </FormLabel>
              <TextField
                name="addressLine2"
                onBlur={formik.handleBlur}
                value={formik.values.addressLine2}
                error={
                  !!formik.touched.addressLine2 && !!formik.errors.addressLine2
                }
                helperText={
                  formik.touched.addressLine2 && formik.errors.addressLine2
                }
                onChange={(e) => setFieldValue("addressLine2", e.target.value)}
                type="text"
                variant="outlined"
                size="small"
                placeholder="Address Line 2"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="sortby-label"
              >
                City
              </FormLabel>
              <TextField
                name="city"
                onBlur={formik.handleBlur}
                value={formik.values.city}
                error={!!formik.touched.city && !!formik.errors.city}
                helperText={formik.touched.city && formik.errors.city}
                onChange={(e) => setFieldValue("city", e.target.value)}
                type="text"
                variant="outlined"
                size="small"
                placeholder="City"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="sortby-label"
              >
                Country
              </FormLabel>
              <FormControl
                size="small"
                sx={{ width: "290px", bgcolor: "#fff" }}
              >
                <Select onChange={() => {}} value={formik.values.country}>
                  {countriesWithSymbols?.map((row) => (
                    <MenuItem
                      onClick={() => {
                        setFieldValue("country", row.country);
                        setFieldValue("currencySymbol", row.symbol);
                        setFieldValue("gstOrVat", row.gstOrVat);
                      }}
                      value={row.country}
                    >
                      {row.country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="sortby-label"
              >
                Postal Code
              </FormLabel>
              <TextField
                name="postalCode"
                onBlur={formik.handleBlur}
                value={formik.values.postalCode}
                error={
                  !!formik.touched.postalCode && !!formik.errors.postalCode
                }
                helperText={
                  formik.touched.postalCode && formik.errors.postalCode
                }
                onChange={(e) => setFieldValue("postalCode", e.target.value)}
                type="text"
                variant="outlined"
                size="small"
                placeholder="Postal Code"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="sortby-label"
              >
                Business Timing
              </FormLabel>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <TextField
                  name="businessStartTime"
                  onBlur={formik.handleBlur}
                  value={formik.values.businessStartTime}
                  error={
                    !!formik.touched.businessStartTime &&
                    !!formik.errors.businessStartTime
                  }
                  helperText={
                    formik.touched.businessStartTime &&
                    formik.errors.businessStartTime
                  }
                  onChange={(e) =>
                    setFieldValue("businessStartTime", e.target.value)
                  }
                  type="time"
                  variant="outlined"
                  size="small"
                  sx={{ width: "133px", bgcolor: "#fff" }}
                />
                <FormLabel sx={{ color: "#000" }}>To</FormLabel>
                <TextField
                  name="businessEndTime"
                  onBlur={formik.handleBlur}
                  value={formik.values.businessEndTime}
                  error={
                    !!formik.touched.businessEndTime &&
                    !!formik.errors.businessEndTime
                  }
                  helperText={
                    formik.touched.businessEndTime &&
                    formik.errors.businessEndTime
                  }
                  onChange={(e) =>
                    setFieldValue("businessEndTime", e.target.value)
                  }
                  type="time"
                  variant="outlined"
                  size="small"
                  sx={{ width: "133px", bgcolor: "#fff" }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="title-label"
              >
                Time Zome
              </FormLabel>
              <Select
                name="title"
                onBlur={formik.handleBlur}
                value={formik.values.timeZome}
                error={!!formik.touched.timeZome && !!formik.errors.timeZome}
                helperText={formik.touched.timeZome && formik.errors.timeZome}
                onChange={(e) => setFieldValue("timeZome", e.target.value)}
                id="title-label"
                size="small"
                sx={{ width: "290px", bgcolor: "#fff" }}
              >
                <MenuItem value={"+05:30"}>+05:30</MenuItem>
                <MenuItem value={"+03:00"}>+03:00</MenuItem>
                <MenuItem value={"+04:00"}>+04:00</MenuItem>
              </Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="title-label"
              >
                GST or VAT
              </FormLabel>
              <Select
                name="title"
                onBlur={formik.handleBlur}
                value={formik.values.gstPercentage}
                error={
                  !!formik.touched.gstPercentage &&
                  !!formik.errors.gstPercentage
                }
                helperText={
                  formik.touched.gstPercentage && formik.errors.gstPercentage
                }
                onChange={(e) => setFieldValue("gstPercentage", e.target.value)}
                id="title-label"
                size="small"
                sx={{ width: "290px", bgcolor: "#fff" }}
              >
                <MenuItem value={0}>0 %</MenuItem>
                <MenuItem value={5}>5 %</MenuItem>
                <MenuItem value={18}>18 %</MenuItem>
              </Select>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="sortby-label"
              >
                TRN
              </FormLabel>
              <TextField
                name="TRN"
                onBlur={formik.handleBlur}
                value={formik.values.TRN}
                error={!!formik.touched.TRN && !!formik.errors.TRN}
                helperText={formik.touched.TRN && formik.errors.TRN}
                onChange={(e) => setFieldValue("TRN", e.target.value)}
                type="text"
                variant="outlined"
                size="small"
                placeholder="Tax Registration Number"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
            <br />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="sortby-label"
              >
                Instagram
              </FormLabel>
              <TextField
                name="instagram"
                onBlur={formik.handleBlur}
                value={formik.values.instagram}
                error={!!formik.touched.instagram && !!formik.errors.instagram}
                helperText={formik.touched.instagram && formik.errors.instagram}
                onChange={(e) => setFieldValue("instagram", e.target.value)}
                type="text"
                variant="outlined"
                size="small"
                placeholder="Instagram"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="sortby-label"
              >
                You Tube
              </FormLabel>
              <TextField
                name="youTube"
                onBlur={formik.handleBlur}
                value={formik.values.youTube}
                error={!!formik.touched.youTube && !!formik.errors.youTube}
                helperText={formik.touched.youTube && formik.errors.youTube}
                onChange={(e) => setFieldValue("youTube", e.target.value)}
                type="text"
                variant="outlined"
                size="small"
                placeholder="You Tube"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="sortby-label"
              >
                Google Account
              </FormLabel>
              <TextField
                name="googleAccount"
                onBlur={formik.handleBlur}
                value={formik.values.googleAccount}
                error={
                  !!formik.touched.googleAccount &&
                  !!formik.errors.googleAccount
                }
                helperText={
                  formik.touched.googleAccount && formik.errors.googleAccount
                }
                onChange={(e) => setFieldValue("googleAccount", e.target.value)}
                type="text"
                variant="outlined"
                size="small"
                placeholder="Google Account"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
          </Box>
          <Typography sx={{ fontFamily: "poppins", fontWeight: "bold" }}>
            Delivery Details
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="sortby-label"
              >
                Min Order Value
              </FormLabel>
              <TextField
                name="minOrderValue"
                onBlur={formik.handleBlur}
                value={formik.values.minOrderValue}
                error={
                  !!formik.touched.minOrderValue &&
                  !!formik.errors.minOrderValue
                }
                helperText={
                  formik.touched.minOrderValue && formik.errors.minOrderValue
                }
                onChange={(e) => setFieldValue("minOrderValue", e.target.value)}
                type="text"
                variant="outlined"
                size="small"
                placeholder="Min Order Value"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="sortby-label"
              >
                Delivery charge per item
              </FormLabel>
              <TextField
                name="postalCode"
                onBlur={formik.handleBlur}
                value={formik.values.deliveryChargePerItem}
                error={
                  !!formik.touched.deliveryChargePerItem &&
                  !!formik.errors.deliveryChargePerItem
                }
                helperText={
                  formik.touched.deliveryChargePerItem &&
                  formik.errors.deliveryChargePerItem
                }
                onChange={(e) =>
                  setFieldValue("deliveryChargePerItem", e.target.value)
                }
                type="text"
                variant="outlined"
                size="small"
                placeholder="Delivery charge per item"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="sortby-label"
              >
                Delivery Radius in Km
              </FormLabel>
              <TextField
                name="deliveryRadius"
                onBlur={formik.handleBlur}
                value={formik.values.deliveryRadius}
                error={
                  !!formik.touched.deliveryRadius &&
                  !!formik.errors.deliveryRadius
                }
                helperText={
                  formik.touched.deliveryRadius && formik.errors.deliveryRadius
                }
                onChange={(e) =>
                  setFieldValue("deliveryRadius", e.target.value)
                }
                type="text"
                variant="outlined"
                size="small"
                placeholder="Delivery Radius"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <FormLabel
                sx={{ color: "#000", fontFamily: "poppins" }}
                id="sortby-label"
              >
                Free Delivery Value
              </FormLabel>
              <TextField
                name="freeDeliveryValue"
                onBlur={formik.handleBlur}
                value={formik.values.freeDeliveryValue}
                error={
                  !!formik.touched.freeDeliveryValue &&
                  !!formik.errors.freeDeliveryValue
                }
                helperText={
                  formik.touched.freeDeliveryValue &&
                  formik.errors.freeDeliveryValue
                }
                onChange={(e) =>
                  setFieldValue("freeDeliveryValue", e.target.value)
                }
                type="text"
                variant="outlined"
                size="small"
                placeholder="Free Delivery Value"
                sx={{ width: "290px", bgcolor: "#fff" }}
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "20px",
              // bgcolor: "blue",
              maxWidth: "1220px",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                bgcolor: "#1C62F1",
                "&:hover": {
                  backgroundColor: "#1C62F1",
                },
                width: "130px",
              }}
            >
              Save & Next
            </Button>
            <Button
              onClick={() => setState("")}
              variant="contained"
              sx={{
                bgcolor: "#8B8B8B",
                "&:hover": {
                  backgroundColor: "#8B8B8B",
                },
                width: "130px",
              }}
            >
              Cancel
            </Button>
          </Box>
        </>
      </Box>
    </>
  );
}
