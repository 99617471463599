import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Icon from "../assets/shopwell.png";
import DashboardIcon from "@mui/icons-material/Dashboard";
import MenuIcon from "@mui/icons-material/Menu";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";

const drawerWidth = 270;

export default function SideNavbar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleLogout = () => {
    Cookies.set("master", "");
    dispatch({
      type: "MASTER_LOGOUT",
    });
    navigate("/masterlogin");
  };
  const location = useLocation();

  const MenuNav = [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: <DashboardIcon style={{ color: "#737173" }} />,
    },
    {
      name: "Category",
      href: "#",
      icon: <DashboardIcon style={{ color: "#737173" }} />,
    },
    {
      name: "Vendor",
      href: "/allvendors",
      icon: <DashboardIcon style={{ color: "#737173" }} />,
    },
    {
      name: "Report",
      href: "#",
    },
    {
      name: "Accounting",
      href: "#",
    },
    {
      name: "Subscription",
      href: "/subscription",
    },
    {
      name: "Payment",
      href: "/payment",
    },
    {
      name: "User Management",
      href: "#",
    },
  ];

  const MarketingNav = [
    {
      name: "Banner",
      href: "#",
    },
    {
      name: "Promotion",
      href: "#",
    },
    {
      name: "Reviews",
      href: "#",
    },
  ];

  const SupportNav = [
    {
      name: "Configuration",
      href: "#",
    },
    {
      name: "Settings",
      href: "#",
    },
    {
      name: "Logout",
      func: handleLogout,
    },
  ];

  const [menu] = React.useState(MenuNav);
  const [marketing] = React.useState(MarketingNav);
  const [support] = React.useState(SupportNav);

  const drawer = (
    <Box
      sx={{
        bgcolor: "#fff",
        height: "100%",
        overflow: "auto",
        py: 5,
        "&::-webkit-scrollbar": {
          display: "none", // Hide scrollbar
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src={Icon} alt="logo" width={"130px"} />
      </Box>

      <Typography
        style={{
          color: "#DBDADC",
          paddingLeft: "30px",
          paddingTop: "30px",
          fontFamily: "poppins",
        }}
      >
        MENU
      </Typography>
      <List sx={{ px: 3 }}>
        {menu.map((item, index) => (
          <Link to={item.href} style={{ textDecoration: "none" }}>
            <ListItem key={index} disablePadding>
              <ListItemButton
                disablePadding
                sx={{
                  background: location.pathname === item.href ? "#EFF3FF" : "",
                  borderLeft:
                    location.pathname === item.href ||
                    (location.pathname === "/addvendor" &&
                      item.href === "/allvendors") ||
                    (location.pathname === "/upgrade-subscription" &&
                      item.href === "/subscription")
                      ? "6px solid #1C62F1"
                      : "",
                  ":hover": {
                    bgcolor:
                      location.pathname === item.href ? "#EFF3FF" : "#EFF3FF",
                    borderLeft: "6px solid #1C62F1",
                  },
                  borderRadius: "5px",
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontFamily: "poppins" }}>
                      {item.name}
                    </Typography>
                  }
                  style={{ color: "#737173", marginLeft: "-20px" }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
      <Typography
        style={{
          color: "#DBDADC",
          fontFamily: "poppins",
          paddingLeft: "30px",
          paddingTop: "30px",
        }}
      >
        MARKETING
      </Typography>
      <List sx={{ px: 3 }}>
        {marketing.map((item, index) => (
          <Link to={item.href} style={{ textDecoration: "none" }}>
            <ListItem key={index} disablePadding>
              <ListItemButton
                disablePadding
                sx={{
                  background: location.pathname === item.href ? "#EFF3FF" : "",
                  borderLeft:
                    location.pathname === item.href ? "6px solid #1C62F1" : "",
                  ":hover": {
                    bgcolor:
                      location.pathname === item.href ? "#EFF3FF" : "#EFF3FF",
                    borderLeft: "6px solid #1C62F1",
                  },
                  borderRadius: "5px",
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={{ fontFamily: "poppins" }}>
                      {item.name}
                    </Typography>
                  }
                  style={{ color: "#737173", marginLeft: "-20px" }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
      <Typography
        style={{
          color: "#DBDADC",
          paddingLeft: "30px",
          fontFamily: "poppins",
          paddingTop: "30px",
        }}
      >
        SUPPORT
      </Typography>
      <List sx={{ px: 3 }}>
        {support.map((item, index) => (
          <Link to={item.href} style={{ textDecoration: "none" }}>
            <ListItem key={index} disablePadding>
              <ListItemButton
                disablePadding
                sx={{
                  background: location.pathname === item.href ? "#EFF3FF" : "",
                  borderLeft:
                    location.pathname === item.href ? "6px solid #1C62F1" : "",
                  ":hover": {
                    bgcolor:
                      location.pathname === item.href ? "#EFF3FF" : "#EFF3FF",
                    borderLeft: "6px solid #1C62F1",
                  },
                  borderRadius: "5px",
                }}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  onClick={item.func}
                  primary={
                    <Typography sx={{ fontFamily: "poppins" }}>
                      {item.name}
                    </Typography>
                  }
                  style={{ color: "#737173", marginLeft: "-20px" }}
                />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      {location.pathname === "/" ||
      location.pathname === "/allvendors" ||
      location.pathname === "/addvendor" ||
      location.pathname === "/subscription" ||
      location.pathname === "/upgrade-subscription" ||
      location.pathname === "/payment" ? (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mx: 2, py: 3, display: { sm: "none" }, position: "absolute" }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            //   container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
            open
          >
            {drawer}
          </Drawer>
        </>
      ) : (
        ""
      )}
    </>
  );
}
