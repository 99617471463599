import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { LiaUserEditSolid } from "react-icons/lia";
import { getAllVendors } from "../services/master";
import { useSelector } from "react-redux";
import styled from "styled-components";
import AddVendorTab from "../componets/AddVendorTab";
import EditVendor from "../componets/EditVendor";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  color: "var(--border-black)",
}));
const drawerWidth = 270;

export default function AllVendors() {
  const { master } = useSelector((state) => state);
  const [state, setState] = useState("view");
  const [vendorDetails, setVendorDetails] = useState({});
  const [vendors, setAllVendors] = useState([]);
  const fetchData = async () => {
    const data = await getAllVendors(master.token);
    setAllVendors(data);
  };

  useEffect(() => {
    fetchData();
  }, [state]);

  return (
    <>
      <Box
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        {state === "add" ? (
          <Box>
            <Typography
              sx={{
                px: 3,
                py: 2,
                "@media (max-width: 600px)": {
                  pl: 8, // margin value for small screens only
                },
                color: "#737173",
                fontSize: "25px",
              }}
            >
              Add Vendor
            </Typography>
            <Divider />
            <AddVendorTab setState={setState} />
          </Box>
        ) : state === "edit" ? (
          <Box>
            <Typography
              sx={{
                px: 3,
                py: 2,
                "@media (max-width: 600px)": {
                  pl: 8, // margin value for small screens only
                },
                color: "#737173",
                fontSize: "25px",
              }}
            >
              Edit Vendor
            </Typography>
            <Divider />
            <EditVendor setState={setState} vendorDetails={vendorDetails} />
          </Box>
        ) : (
          <>
            <Typography
              sx={{
                px: 3,
                py: 2,
                "@media (max-width: 600px)": {
                  pl: 8, // margin value for small screens only
                },
                color: "#737173",
                fontSize: "25px",
              }}
            >
              Vendor
            </Typography>
            <Divider />
            <Box
              sx={{
                py: 3,
                px: 3,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <TextField
                id="outlined-basic"
                placeholder="Search"
                variant="outlined"
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ bgcolor: "#fff" }}
              />
              <Button variant="contained" onClick={() => setState("add")}>
                Add Vendor
              </Button>
            </Box>
            <Box sx={{ mx: 3 }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Sl No</StyledTableCell>
                      <StyledTableCell align="center">
                        Business Name
                      </StyledTableCell>
                      <StyledTableCell align="center">Phone</StyledTableCell>
                      <StyledTableCell align="center">Location</StyledTableCell>
                      <StyledTableCell align="center">Email</StyledTableCell>
                      <StyledTableCell align="center">Country</StyledTableCell>
                      <StyledTableCell align="center">Action</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {vendors.length > 0 &&
                      vendors.map((row, index) => (
                        <TableRow key={row.name}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            {row.businessName}
                          </TableCell>
                          <TableCell align="center">{row.phone}</TableCell>
                          <TableCell align="center">
                            {row.businessAddress}
                          </TableCell>
                          <TableCell align="center">{row.email}</TableCell>
                          <TableCell align="center">{row.country}</TableCell>
                          <TableCell align="center">
                            <LiaUserEditSolid
                              size={25}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setState("edit");
                                setVendorDetails(row);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
