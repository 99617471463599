import axios from "axios";
export const host = process.env.REACT_APP_BACKEND_URL;

export const masterLogin = async (datas) => {
  try {
    const { data } = await axios.post(`${host}/masterLogin`, datas);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const vendorRegister = async (token, datas) => {
  try {
    const { data } = await axios.post(`${host}/vendorRegister`, datas, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
        "Access-Control-Allow-Origin": "*",
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const editVendor = async (token, datas) => {
  try {
    const { data } = await axios.put(`${host}/editVendor`, datas, {
      headers: {
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getAllVendors = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getAllVendors`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
