import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormLabel,
  InputAdornment,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { vendorRegister } from "../services/master";
import { toast } from "react-hot-toast";
import { useSelector } from "react-redux";

function createData(startsDate, version, cycle, amount, expireDate) {
  return { startsDate, version, cycle, amount, expireDate };
}

const rows = [
  createData("17/01/2023", "Lite/per annum", "2", "$200", "17/01/2025"),
];

export default function Subscription({ setState, values }) {
  const { master } = useSelector((state) => state);

  const [billingPeriod, setBillingPeriod] = useState("Annum");
  const handleChange = (event) => {
    setBillingPeriod(event.target.value);
  };
  const initialValues = {
    ...values,
    // package: "",
  };
  const subscriptions = [
    { price: 100, version: "Lite" },
    { price: 150, version: "Standard" },
    { price: 200, version: "Pro" },
  ];
  const [subValue, setSubValue] = useState("Lite");
  const handleSubChange = (event) => {
    setSubValue(event.target.value);
  };
  const handleButtonClick = (value) => {
    setSubValue(value);
  };

  const formik = useFormik({
    initialValues: initialValues,
    // validationSchema: vendorRegisterSchema,
    onSubmit: async (values, action) => {
      const formData = new FormData();
      formData.append("data", JSON.stringify(values));
      formData.append("image", values.logo);
      const data = await vendorRegister(master.token, formData);
      if (data.status) {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    },
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <FormControl>
          <RadioGroup
            overlay
            name="subscription"
            defaultValue="Lite"
            value={subValue}
            onChange={handleSubChange}
          >
            <Box sx={{ display: "flex", gap: "20px", flexWrap: "wrap" }}>
              {subscriptions.map((sub, idx) => (
                <Card
                  key={idx}
                  variant="outlined"
                  sx={{
                    width: 270,
                    border: "1px solid #1C62F1",
                    bgcolor: subValue === sub.version ? "#1C62F1" : "#fff",
                    color: subValue === sub.version ? "#fff" : "#000",
                  }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography>{sub.version}</Typography>
                      <Radio
                        value={sub.version}
                        sx={{
                          color: "#000",
                          "&.Mui-checked": { color: "#fff" },
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontWeight: "500", fontSize: "54px" }}>
                        ${sub.price}
                      </Typography>
                      <Typography>Per Annum</Typography>
                    </Box>
                  </CardContent>
                  <CardActions>
                    <Box sx={{ display: "flex", width: "100%" }}>
                      {subValue === sub.version ? (
                        <Button
                          variant="conatined"
                          sx={{
                            flex: 1,
                            mx: 3,
                            mb: 3,
                            bgcolor: "#fff",
                            color: "#1C62F1",
                            "&:hover": { backgroundColor: "#fff" },
                          }}
                        >
                          Selected
                        </Button>
                      ) : (
                        <Button
                          variant="outlined"
                          sx={{
                            flex: 1,
                            mx: 3,
                            mb: 3,
                            bgcolor: "#fff",
                            color: "#1C62F1",
                          }}
                          onClick={() => handleButtonClick(sub.version)}
                        >
                          Choose
                        </Button>
                      )}
                    </Box>
                  </CardActions>
                </Card>
              ))}
            </Box>
          </RadioGroup>
        </FormControl>

        <Box sx={{ my: 3 }}>
          <Typography sx={{ color: "#1C62F1" }}>Subscription Terms</Typography>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "column",
              // gap: "45px",
              alignItems: "center",
              mt: 3,
            }}
          >
            <FormLabel
              sx={{ color: "#000", width: "200px" }}
              id="firstName-label"
            >
              Starts on
            </FormLabel>
            <TextField
              type="date"
              variant="outlined"
              size="small"
              sx={{ width: "290px", bgcolor: "#fff" }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              // flexDirection: "column",
              // gap: "45px",
              alignItems: "center",
              mt: 3,
            }}
          >
            <FormLabel
              sx={{ color: "#000", width: "200px" }}
              id="firstName-label"
            >
              Billing Period
            </FormLabel>
            <Select
              id="title-label"
              value={billingPeriod}
              onChange={handleChange}
              size="small"
              sx={{ width: "290px", bgcolor: "#fff" }}
            >
              <MenuItem value={"Annum"}>Annum</MenuItem>
              <MenuItem value={"Monthly"}>Monthly</MenuItem>
            </Select>
          </Box>

          <Box
            sx={{
              display: "flex",
              // flexDirection: "column",
              // gap: "45px",
              alignItems: "center",
              mt: 3,
            }}
          >
            <FormLabel
              sx={{ color: "#000", width: "200px" }}
              id="firstName-label"
            >
              Expires After
            </FormLabel>
            <TextField
              type="number"
              variant="outlined"
              size="small"
              sx={{ width: "290px", bgcolor: "#fff" }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Cycles</InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              // flexDirection: "column",
              // gap: "45px",
              alignItems: "center",
              mt: 3,
            }}
          >
            <FormLabel
              sx={{ color: "#000", width: "200px" }}
              id="firstName-label"
            >
              Expire date on
            </FormLabel>
            <TextField
              type="date"
              variant="outlined"
              size="small"
              sx={{ width: "290px", bgcolor: "#fff" }}
            />
          </Box>
        </Box>

        <Box>
          <Typography sx={{ color: "#1C62F1" }}>Invoice</Typography>
          <Box sx={{ width: "800px", my: 3 }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{ bgcolor: "#F3F6FB" }}>
                  <TableRow>
                    <TableCell>Starts on</TableCell>
                    <TableCell align="right">Package</TableCell>
                    <TableCell align="right">No. of Cycles</TableCell>
                    <TableCell align="right">Amount</TableCell>
                    <TableCell align="right">Expires on</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.startsDate}
                      </TableCell>
                      <TableCell align="right">{row.version}</TableCell>
                      <TableCell align="right">{row.cycle}</TableCell>
                      <TableCell align="right">{row.amount}</TableCell>
                      <TableCell align="right">{row.expireDate}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{ width: "800px", display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              variant="contained"
              sx={{
                bgcolor: "#1C62F1",
                "&:hover": {
                  backgroundColor: "#1C62F1",
                },
                width: "100px",
              }}
            >
              Print
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            // justifyContent: "flex-end",
            gap: "20px",
            // bgcolor: "blue",
            maxWidth: "1220px",
            my: 3,
          }}
        >
          <Button
            type="submit"
            variant="contained"
            sx={{
              bgcolor: "#1C62F1",
              "&:hover": {
                backgroundColor: "#1C62F1",
              },
              width: "100px",
            }}
          >
            Save
          </Button>
          <Button
            onClick={() => setState("")}
            variant="contained"
            sx={{
              bgcolor: "#8B8B8B",
              "&:hover": {
                backgroundColor: "#8B8B8B",
              },
              width: "100px",
            }}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </>
  );
}
