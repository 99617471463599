import { Box, Tab } from "@mui/material";
import React, { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Overview from "./Overview";
import Subscription from "./Subscription";
import { useFormik } from "formik";
import { vendorRegisterSchema } from "../schemas/Index";

export default function AddVendorTab({ setState }) {
  const [value, setValue] = useState("1");
  const initialValues = {
    title: "Mr.",
    first_name: "",
    last_name: "",
    phone: "",
    mobile: "",
    email: "",
    password: "",
    POSpassword: "",
    businessName: "",
    businessAddress: "",
    addressLine1: "",
    addressLine2: "",
    gstPercentage: 0,
    gstOrVat: "",
    timeZome: "+05:30",
    city: "",
    country: "",
    currencySymbol: "",
    postalCode: "",
    TRN: "",
    businessStartTime: "",
    businessEndTime: "",
    logo: "",
    minOrderValue: "",
    deliveryChargePerItem: "",
    deliveryRadius: "",
    freeDeliveryValue: "",
    instagram: "",
    youTube: "",
    googleAccount: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: vendorRegisterSchema,
    onSubmit: async (values, action) => {
      setValue("2");
    },
  });
  return (
    <>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={(e, v) => setValue(v)}
            aria-label="lab API tabs example"
          >
            <Tab label="Overview" value="1" />
            <Tab label="Subscription" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Overview
            setState={setState}
            setFieldValue={formik.setFieldValue}
            formik={formik}
          />
        </TabPanel>
        <TabPanel value="2">
          <Subscription setState={setState} values={formik.values} />
        </TabPanel>
      </TabContext>
    </>
  );
}
